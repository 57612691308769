<aside class="sidebar flex flex-col">
	<div class="text-center">
		<a routerLink="/">
			<img
				class="relative h-16 lg:h-22 my-0 mx-auto"
				src="../../../../assets/logo-emypeople.png"
				alt="eMyPeople logo"
			/>
			<p class="font-bold leading-none">Dashboard</p>
		</a>
	</div>
	<div class="grow">
		@if (shouldDisplayDashboard() && customer()?.code) {
			<div class="customer-options rounded p-2 mt-16">
				<p class="text-center text-xl pt-1 mb-3 px-5 truncate">{{ customer()?.name }}</p>
				@for (item of customerButtons; track item.path) {
					<a [routerLink]="getPath(item.path)" class="button-link" (click)="closeSidenav()">
						<csd-app-button
							classList="text-left w-full font-bold mb-1"
							[icon]="item.icon"
							type="text"
							[active]="validateActiveRoute(item.path)"
						>
							{{ item.label }}
						</csd-app-button>
					</a>
				}
			</div>
		}
	</div>
	<div class="user rounded">
		@if (shouldDisplayDashboard()) {
			@for (item of configButtons(); track item.path) {
				<a
					[routerLink]="item.path"
					class="button-link"
					routerLinkActive="button-link--active"
					(click)="closeSidenav()"
				>
					<csd-app-button
						classList="text-left w-full font-bold mb-1"
						[icon]="item.icon"
						iconClassList="material-symbols-filled"
						type="text"
						>{{ item.label }}</csd-app-button
					>
				</a>
			}
		}
		<hr class="separator" />
		<csd-app-user-area [isMobile]="isMobile()"></csd-app-user-area>
	</div>
</aside>
