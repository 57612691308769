import { DatePipe, formatDate } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	inject,
	OnDestroy,
	OnInit,
	signal,
} from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { DeviceDetailsService, NotesUpdate } from '@feature/device-details/device-details.service';
import { Note } from '@feature/device-details/device-details.types';
import { DevicesStore } from '@feature/devices/devices.store';
import { UserStore } from '@feature/user/user.store';
import { LoaderComponent } from '@ui/loader/loader.component';
import { LoadingIconComponent } from '@ui/loading-icon/loading-icon.component';
import { finalize, Subscription } from 'rxjs';

@Component({
	selector: 'csd-app-notes',
	standalone: true,
	imports: [
		DatePipe,
		FormsModule,
		LoaderComponent,
		LoadingIconComponent,
		MatIconModule,
		MatRippleModule,
		ReactiveFormsModule,
	],
	templateUrl: './notes.component.html',
	styleUrl: './notes.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotesComponent implements OnInit, OnDestroy {
	readonly #userStore = inject(UserStore);
	readonly #devicesStore = inject(DevicesStore);
	#deviceDetailsService = inject(DeviceDetailsService);
	#formBuilder = inject(FormBuilder);

	deviceId = this.#devicesStore.selectedDeviceId;
	form = this.#formBuilder.group({
		comment: ['', [Validators.required, Validators.minLength(1)]],
	});
	isLoadingData = signal(false);
	isUpdating = signal(false);
	notes = signal<Note[]>([]);
	subscriptions: Subscription[] = [];

	ngOnInit(): void {
		this.loadNotes();
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((sub) => sub?.unsubscribe());
	}

	displayNotes(notes: Note[]) {
		this.notes.set(notes);
	}

	loadNotes() {
		this.isLoadingData.set(true);

		const sub$ = this.#deviceDetailsService
			.loadNotes(this.deviceId())
			.pipe(finalize(() => this.isLoadingData.set(false)))
			.subscribe(this.displayNotes.bind(this));

		this.subscriptions.push(sub$);
	}

	onSubmitNote() {
		const commentValue = this.form.controls.comment.value?.trim();

		if (this.form.invalid || !commentValue) {
			return;
		}

		const formData = {
			identifier: this.deviceId(),
			commenter: this.#userStore.user()?.name,
			comment: commentValue,
		};

		this.isUpdating.set(true);
		this.form.disable();
		this.saveNotes(formData);
	}

	onSavingNoteSuccess(noteId: string) {
		const clearId = noteId.replace(/("|')/gi, '');
		const commentValue = this.form.controls.comment.value?.trim();
		const formatDateString = 'yyyy-MM-ddTHH:mm:ss.SSSZZZZZ';
		this.form.enable();
		this.form.reset();

		this.notes.set([
			...(this.notes() || []),
			{
				id: clearId,
				identifier: this.deviceId(),
				commenter: this.#userStore.user()?.name,
				comment: commentValue as string,
				timeCommented: formatDate(new Date(), formatDateString, 'en-US', 'UTC'),
			},
		]);
	}

	saveNotes(data: NotesUpdate) {
		const sub$ = this.#deviceDetailsService
			.saveNotes(data)
			.pipe(finalize(() => this.isUpdating.set(false)))
			.subscribe(this.onSavingNoteSuccess.bind(this));

		this.subscriptions.push(sub$);
	}
}
