import {
	Component,
	effect,
	EffectRef,
	inject,
	input,
	OnDestroy,
	OnInit,
	signal,
} from '@angular/core';
import { Event, NavigationStart, Router } from '@angular/router';
import { LoaderFullScreenComponent } from '@ui/loader-full-screen/loader-full-screen.component';
import { filter, Subscription } from 'rxjs';

import { UserService } from '../../user/user.service';
import { UserStore } from '../../user/user.store';

@Component({
	selector: 'csd-app-login-callback',
	standalone: true,
	imports: [LoaderFullScreenComponent],
	templateUrl: './login-callback.component.html',
})
export class LoginCallbackComponent implements OnInit, OnDestroy {
	readonly #store = inject(UserStore);
	#userService = inject(UserService);
	#router = inject(Router);

	code = input<string>('');
	state = input<string>('');

	isContenListLoaded = signal<boolean>(false);
	routeToRedirect = signal<string>('');
	statusText: string = 'Loading';

	effectRef!: EffectRef;
	subscriptions: Subscription[] = [];

	constructor() {
		this.effectRef = effect(
			() => {
				if (this.#store.user().sub) {
					this.retrieveListContent();
				}
			},
			{ allowSignalWrites: true },
		);
	}

	ngOnInit() {
		this.isValidSession();

		const sub$ = this.#router.events
			.pipe(filter((event) => event instanceof NavigationStart))
			.subscribe((event: Event) => {
				const navigation = event as NavigationStart;
				this.routeToRedirect.set(navigation.url);
			});

		this.subscriptions.push(sub$ as Subscription);
	}

	ngOnDestroy(): void {
		this.effectRef.destroy();
		this.subscriptions?.forEach((sub) => sub?.unsubscribe());
	}

	isValidSession() {
		if (this.code() && this.state()) {
			return;
		}

		this.#router.navigate(['/authentication/login']);
	}

	retrieveListContent() {
		this.statusText = 'Authorizing';

		const listSub$ = this.#userService
			.getListContent(this.#store.user().sub)
			.subscribe(this.validateList.bind(this));

		this.subscriptions.push(listSub$ as Subscription);
	}

	validateList(content: unknown) {
		const isEmptyList = !content || (Array.isArray(content) && content.length === 0);

		this.isContenListLoaded.set(true);

		if (isEmptyList) {
			this.#router.navigate(['/onboarding'], { replaceUrl: true });
			return;
		}

		// Route to the last page visited before the login
		this.#router.navigate([this.routeToRedirect()], { replaceUrl: true });
	}
}
