import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { GroupsStore } from '@feature/groups/groups.store';
import { UserStore } from '@feature/user/user.store';

export const appsGuard: CanActivateFn = () => {
	const groupsStore = inject(GroupsStore);
	const userStore = inject(UserStore);
	const selectedGroup = groupsStore.getGroup(userStore.selectedGroupId());

	return !!selectedGroup?.appsAccess;
};
