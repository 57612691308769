import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { CompanyGroup } from '@feature/groups/groups.types';
import { UserService } from '@feature/user/user.service';
import { UserStore } from '@feature/user/user.store';
import { Observable } from 'rxjs';

export const userResolver: ResolveFn<Observable<CompanyGroup[]>> = () => {
	const userStore = inject(UserStore);
	const userService = inject(UserService);

	return userService.getListContent(userStore.user()?.sub as string);
};
