<csd-app-loader [isLoading]="isLoading()">
	<div class="space-y-5">
		@if (customerPolicies().length) {
			<csd-app-section
				header="{{ customer()?.code }} Android Policies"
				[collapsible]="customerPolicies().length > lengthToExpand"
			>
				<ng-container header-actions>
					<a
						class="hover:text-layout-stone-gray"
						routerLink="./add-policy"
						[queryParams]="{ customer: customer()?.code }"
					>
						<csd-app-button size="small">
							<div class="flex items-center">
								<span class="font-bold text-lg mr-2">
									<mat-icon inline class="align-bottom">add</mat-icon>
								</span>
								Add Policy
							</div>
						</csd-app-button>
					</a>
				</ng-container>

				<csd-app-sortable-list
					[itemsList]="customerPolicies()"
					[disableDrop]="true"
					(itemDeleted)="deletePolicy($event, 'customer')"
				></csd-app-sortable-list>
			</csd-app-section>
		}

		@if (canDisplayPublicPolicies()) {
			<csd-app-section
				header="All Android Policies"
				[collapsible]="isLoading() ? true : publicPolicies().length > lengthToExpand"
			>
				<ng-container header-actions>
					<a routerLink="./add-policy" class="hover:text-layout-stone-gray">
						<csd-app-button size="small">
							<div class="flex items-center">
								<span class="font-bold text-lg mr-2">
									<mat-icon inline class="align-bottom">add</mat-icon>
								</span>
								Add Policy
							</div>
						</csd-app-button>
					</a>
				</ng-container>

				@if (publicPolicies().length) {
					<csd-app-sortable-list
						[itemsList]="publicPolicies()"
						[disableDrop]="true"
						(itemDeleted)="deletePolicy($event, 'public')"
					></csd-app-sortable-list>
				} @else {
					<csd-app-empty-state icon="business_center">
						<ng-container empty-state-header> No policies created </ng-container>
						<ng-container empty-state-message>
							<p class="text-center">
								Create policies to protect your devices and data. Control device settings and
								permissions.
							</p>
						</ng-container>
						<ng-container empty-state-actions>
							<csd-app-button routerLink="./add-policy" variation="secondary"
								>Create policy</csd-app-button
							>
						</ng-container>
					</csd-app-empty-state>
				}
			</csd-app-section>
		}

		@if (!canDisplayPublicPolicies() && !customerPolicies().length) {
			<csd-app-empty-state icon="business_center">
				<ng-container empty-state-header> No Policies Available </ng-container>
				<ng-container empty-state-message>
					<p class="text-center">There are currently no policies to edit.</p>
				</ng-container>
			</csd-app-empty-state>
		}
	</div>
</csd-app-loader>
