import { CompanyGroup } from '@feature/groups/groups.types';

export const CUSTOMER_BUTTONS = [
	{
		icon: 'dvr',
		path: 'overview',
		label: 'Overview',
	},
	{
		icon: 'devices',
		path: 'devices',
		label: 'Devices',
	},
];

export const CONFIG_BUTTONS: {
	icon: string;
	path: string;
	label: string;
	roles?: string[];
	groupAccess?: keyof CompanyGroup;
}[] = [
	{
		icon: 'apps',
		path: '{customer}/apps',
		label: 'Apps',
		groupAccess: 'appsAccess',
	},
	{
		icon: 'business_center',
		path: '{customer}/policies',
		label: 'Policies',
	},
];
