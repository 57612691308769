import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	input,
	output,
	viewChild,
} from '@angular/core';
import { MatRippleModule } from '@angular/material/core';

@Component({
	selector: 'csd-app-button-group',
	standalone: true,
	imports: [CommonModule, MatRippleModule],
	templateUrl: './button-group.component.html',
	styleUrl: './button-group.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonGroupComponent {
	buttonGroupAfter = viewChild<ElementRef>('buttonGroupAfter');

	classList = input('', {
		transform: (value: string | string[]): string => {
			return Array.isArray(value) ? value.join(' ') : value;
		},
	});

	clicked = output<void>();
	clickedAfter = output<void>();

	hasProjectedContent(): boolean {
		const visibleNodes = [...(this.buttonGroupAfter()?.nativeElement?.childNodes || [])].filter(
			(el) => el.nodeType !== Node.COMMENT_NODE,
		);

		return !!visibleNodes.length;
	}
}
