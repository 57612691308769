import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { CustomerChurchGroup } from '@feature/customers/customers.types';
import { catchError, forkJoin, Observable, of } from 'rxjs';

import { CustomersService } from '../customers/customers.service';
import { CustomersStore } from '../customers/customers.store';
import { Customer } from './customer.types';

export const customerResolver: ResolveFn<
	Observable<[Customer, CustomerChurchGroup[]]> | undefined
> = (route, state) => {
	const store = inject(CustomersStore);
	const customersService = inject(CustomersService);
	const customerId = route.paramMap.get('customerId') as string;

	if (customerId === 'public') {
		return;
	}

	const customer = (store.customers()[customerId] as Customer) || {};
	const churchGroups = store.churchGroupsList();

	const customerReq = customer.id ? of(customer) : customersService.getCustomer(customerId);
	const churchGroupsReq =
		customer && churchGroups.length
			? of(churchGroups)
			: customersService.getChurchGroupByCustomer(customerId);

	return forkJoin([customerReq, churchGroupsReq]).pipe(
		catchError(() => {
			throw new Error('Error getting customer information. Verify the customer ID and try again.');
		}),
	);
};
