<csd-app-loader [isLoading]="isLoadingData()">
	<div class="h-full flex flex-col text-sm">
		<div class="grow space-y-3">
			@for (note of notes(); track note.id) {
				<div class="note-item">
					<header class="note-item__header">
						<span class="max-w-[40%]">{{ note.commenter }}</span>
						<span>・</span>
						<span class="text-layout-shingle-gray max-w-[60%]">
							{{ note.timeCommented | date: 'medium' }}
						</span>
					</header>
					<p class="note-item__text">{{ note.comment }}</p>
				</div>
			}
		</div>
		<div class="comment-form" [formGroup]="form">
			<textarea
				class="comment-form__textarea"
				formControlName="comment"
				placeholder="Comment"
			></textarea>
			<button
				class="comment-form__button"
				matRipple
				[disabled]="isUpdating()"
				(click)="onSubmitNote()"
			>
				Comment
				@if (isUpdating()) {
					<csd-app-loading-icon />
				}
			</button>
		</div>
	</div>
</csd-app-loader>
