import { inject, Injectable } from '@angular/core';
import { ActivationStart, Event } from '@angular/router';
import { AuthService } from '@core/auth/auth.service';
import { CustomersStore } from '@feature/customers/customers.store';
import { filter } from 'rxjs';

import { AppComponent } from './app.component';

@Injectable({
	providedIn: AppComponent,
})
export class AppService {
	#authService = inject(AuthService);
	#customersStore = inject(CustomersStore);

	loadRequiredData() {
		return this.#authService.checkAuth().pipe(filter((data) => data?.isAuthenticated));
	}

	updateSelectedCustomer(event: Event) {
		if (!(event instanceof ActivationStart)) {
			return;
		}

		const customerId = event.snapshot.params['customerId'];
		const oldCustomer = this.#customersStore.selectedCustomerId();
		const isSameCustomer = decodeURIComponent(customerId) && oldCustomer;
		const isPublicCustomer = customerId?.toLowerCase() === 'public';

		if (customerId && !isSameCustomer && !isPublicCustomer) {
			this.#customersStore.updateSelectedCustomer(customerId);
		}
	}
}
