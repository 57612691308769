import { ChangeDetectionStrategy, Component, computed, inject, signal } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { CustomersStore } from '@feature/customers/customers.store';
import { DevicesStore } from '@feature/devices/devices.store';
import { PoliciesStore } from '@feature/policies/policies.store';
import { UserImageComponent } from '@ui/user-image/user-image.component';

@Component({
	selector: 'csd-app-customer-info',
	standalone: true,
	imports: [MatIconModule, MatRippleModule, UserImageComponent],
	templateUrl: './customer-info.component.html',
	styleUrl: './customer-info.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerInfoComponent {
	readonly #customersStore = inject(CustomersStore);
	readonly #devicesStore = inject(DevicesStore);
	readonly #policiesStore = inject(PoliciesStore);
	#router = inject(Router);

	customer = this.#customersStore.selectedCustomer;
	userImg = signal<string>('');

	userName = computed<string>(() => {
		return this.#customersStore.selectedCustomer()?.name || '';
	});

	removeSelection() {
		this.#router.navigate(['/']);
		this.#policiesStore.resetState();
		this.#devicesStore.resetState();
		this.#customersStore.resetState();
	}
}
