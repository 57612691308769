import {
	ChangeDetectionStrategy,
	Component,
	computed,
	effect,
	inject,
	OnDestroy,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { triggerOpenClose } from '@root/app/animations';
import { InlineMessageComponent } from '@ui/inline-message/inline-message.component';
import { InternetExceptionsModalData } from '@ui/modal/internet-exceptions/internet-exceptions.component';
import { ModalService } from '@ui/modal/modal.service';
import { NotificationService } from '@ui/notification/notification.service';
import { catchError, finalize, Subscription } from 'rxjs';

import {
	DeviceAndroidUpdate,
	DeviceDetailsService,
} from '../../device-details/device-details.service';
import { DevicesStore } from '../../devices/devices.store';
import { EXCEPTION_TYPES, PROXY_PAC_REGEX, VPN_FILTER_OPTIONS } from './internet.constants';
import { InternetService } from './internet.service';
import { InternetFormShape, VpnFilters } from './internet.types';
import { StepComponent } from './step/step.component';

@Component({
	selector: 'csd-app-internet',
	standalone: true,
	imports: [
		InlineMessageComponent,
		MatIconModule,
		MatInputModule,
		MatRippleModule,
		MatSelectModule,
		MatSlideToggleModule,
		ReactiveFormsModule,
		StepComponent,
	],
	providers: [InternetService],
	templateUrl: './internet.component.html',
	styleUrl: './internet.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [triggerOpenClose],
})
export class InternetComponent implements OnDestroy {
	readonly #devicesStore = inject(DevicesStore);
	#deviceDetailsService = inject(DeviceDetailsService);
	#fb = inject(FormBuilder);
	#internetService = inject(InternetService);
	#modalService = inject(ModalService);
	#notificationService = inject(NotificationService);

	device = this.#devicesStore.selectedDevice;
	filterOptions = VPN_FILTER_OPTIONS;
	form = this.#fb.group({
		vpn: true,
		browserUrl: ['', [Validators.pattern(PROXY_PAC_REGEX)]],
		browserProxyExceptions: this.#fb.control<string[]>([]),
		proxyPacUrl: ['', [Validators.pattern(PROXY_PAC_REGEX)]],
		proxyExceptions: this.#fb.control<string[]>([]),
		vpnProxy: VpnFilters.Direct,
	});
	subscriptions: Subscription[] = [];

	deviceIp = computed(() => this.device()?.vpnIp);

	constructor() {
		effect(this.setFormDefaultValues.bind(this), { allowSignalWrites: true });
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((sub) => sub?.unsubscribe());
	}

	fieldHasErrors(fieldName: string) {
		const control = this.form.get(fieldName);
		return this.form.dirty && control?.touched && control?.invalid;
	}

	onChangeFormValue() {
		if (this.form.pristine) {
			return;
		}

		this.updateInternetSettings();
	}

	async openExceptionsModal(event: Event, type: string) {
		event.preventDefault();
		event.stopPropagation();

		const updatedProp = EXCEPTION_TYPES[type] as string;
		const exceptions = (this.form.value as Record<string, string | string[]>)[updatedProp];
		await this.#modalService.open('internet-exceptions', {
			type,
			exceptions: exceptions || [],
		} as InternetExceptionsModalData);

		this.#modalService.afterClose((data) => {
			if (!data) {
				return;
			}

			this.form.patchValue({ [updatedProp]: data.exceptions });
			this.updateInternetSettings();
		});
	}

	selectCompareValue(first: string | number, second: string | number) {
		return +first === +second;
	}

	setFormDefaultValues() {
		const device = this.device();

		if (!device) {
			return;
		}

		this.form.patchValue({
			vpn: Boolean(device.vpnOn ?? true),
			browserUrl: device.browserProxyConfigURL,
			browserProxyExceptions: device.browserProxyExceptions || [],
			proxyPacUrl: device.proxyConfigURL,
			proxyExceptions: device.proxyExceptions || [],
			vpnProxy: device.vpnProxy ?? VpnFilters.Direct,
		});
	}

	updateInternetSettings() {
		this.form.markAsDirty();

		if (this.form.invalid) {
			return;
		}

		this.form.disable();
		this.#notificationService.open({ message: 'Updating internet settings...' });

		const device = this.device();
		const formValue = this.form.value as InternetFormShape;
		const data = this.#internetService.buildFormData(formValue, device);

		const update$ = this.#deviceDetailsService.updateDevice(device.id, data as DeviceAndroidUpdate);
		const pipe$ = update$.pipe(
			finalize(() => this.form.enable()),
			catchError(() => {
				this.setFormDefaultValues();
				throw new Error('Error updating internet settings');
			}),
		);
		const sub$ = pipe$.subscribe((updatedDevice) => {
			const message = `Internet settings for <strong>${device.name}</strong> were updated.`;
			const storeData = this.#internetService.prepareDeviceToStore(updatedDevice);
			this.#notificationService.openSuccess({ message });
			this.#devicesStore.updateDeviceDetail(device.id, storeData);
		});

		this.subscriptions.push(sub$);
	}
}
