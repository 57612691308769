import { inject, Injectable } from '@angular/core';
import { DevicesStore } from '@feature/devices/devices.store';
import { GroupBase } from '@feature/policies/policies.types';

import { AndroidDevice } from '../device-details.types';
import { StatsComponent } from './stats.component';

@Injectable({
	providedIn: StatsComponent,
})
export class StatsDataService {
	#devicesStore = inject(DevicesStore);

	getDeviceBaseData(device: AndroidDevice) {
		return {
			name: device.name,
			group: device.group?.id as string,
			churchGroupType: device.group?.id as string,
			uninstallKey: device.uninstallKey,
			releaseChannel: device.releaseChannel,
			workOrder: device.workOrder || this.#devicesStore.selectedSubscription(),
			proxyConfigURL: device.proxyConfigURL,
			proxyExceptions: device.proxyExceptions,
			proxyConfigOn: !device.vpnOn,
			browserProxyConfigURL: device.browserProxyConfigURL,
			browserProxyExceptions: device.browserProxyExceptions,
			browserProxyOn: !device.vpnOn,
			vpnOn: device.vpnOn,
			vpnProxy: device.vpnProxy,
		};
	}

	getDataToUpdate(field: string, fieldValue: string | number, device: AndroidDevice) {
		const isValidField = Number.isInteger(fieldValue) ? (fieldValue as number) >= 0 : !!fieldValue;

		if (!isValidField) {
			return;
		}

		return {
			...this.getDeviceBaseData(device),
			churchGroupType: field === 'group' ? fieldValue : device.group?.id,
			[field]: fieldValue,
		};
	}

	getDataToStore(data: ReturnType<typeof this.getDataToUpdate>, groups: GroupBase[]) {
		return {
			name: data?.name,
			group: (groups as GroupBase[])?.find((group) => group.id === data?.group),
			uninstallKey: data?.uninstallKey,
			releaseChannel: data?.releaseChannel,
			workOrder: data?.workOrder,
		};
	}
}
