import {
	ChangeDetectionStrategy,
	Component,
	computed,
	inject,
	OnDestroy,
	signal,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ModalService } from '@ui/modal/modal.service';
import { NotificationService } from '@ui/notification/notification.service';
import { finalize, Subscription } from 'rxjs';

import { GroupsStore } from '../groups/groups.store';
import { UserGroup } from '../groups/groups.types';
import { UserStore } from '../user/user.store';
import { USER_STATUS_OPTIONS } from './group-detail.constants';
import { CompanyUserUpdate, GroupDetailService } from './group-detail.service';
import { HeaderComponent } from './header/header.component';

@Component({
	selector: 'csd-app-group-detail',
	standalone: true,
	imports: [
		FormsModule,
		HeaderComponent,
		MatIconModule,
		MatRippleModule,
		MatSelectModule,
		MatTableModule,
	],
	templateUrl: './group-detail.component.html',
	styleUrl: './group-detail.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GroupDetailComponent implements OnDestroy {
	readonly #groupsStore = inject(GroupsStore);
	readonly #userStore = inject(UserStore);
	#groupDetailService = inject(GroupDetailService);
	#modalService = inject(ModalService);
	#notificationService = inject(NotificationService);

	displayedColumns = ['name', 'username', 'role', 'actions'];
	group = this.#groupsStore.selectedGroup;
	selectedUserIndex = signal<number>(-1);
	subscriptions: Subscription[] = [];
	userRole = signal<number>(-1);
	userStatusOptions = USER_STATUS_OPTIONS;

	dataSource = computed(() => {
		const usersList = this.group()?.usersList || [];
		const currentUserId = this.#userStore.user()?.sub as string;

		return new MatTableDataSource(
			usersList.filter((el) => el.dashboard && el.user.sub !== currentUserId),
		);
	});

	ngOnDestroy(): void {
		this.subscriptions.forEach((el) => el?.unsubscribe());
	}

	async confirmRemoveUser(user?: UserGroup) {
		let userData = user;
		const groupData = {
			groupName: this.group()?.groupName,
			groupId: this.group()?.access,
			isCurrentUser: !user,
		};

		if (!userData) {
			userData = this.group()?.usersList?.find((u) => u.user.sub === this.#userStore.user()?.sub);
		}

		const data = { ...groupData, ...userData };
		await this.#modalService.open('user-remove', data);
	}

	onChangeStatus(event: MatSelectChange, index: number) {
		const { value } = event;
		const selectedUser = this.group()?.usersList?.[index];
		const data: CompanyUserUpdate = {
			status: value,
			dashboard: true,
			userSub: selectedUser?.user.sub as string,
		};

		this.#notificationService.open({ message: 'Changing user role...' });
		this.selectedUserIndex.set(index);
		this.userRole.set(value);
		const sub$ = this.#groupDetailService
			.updateUserData(this.group()?.access as string, data)
			.pipe(
				finalize(() => {
					this.selectedUserIndex.set(-1);
					this.userRole.set(-1);
				}),
			)
			.subscribe(this.onChangeStatusSuccess.bind(this));

		this.subscriptions.push(sub$);
	}

	onChangeStatusSuccess() {
		const selectedUser = this.group()?.usersList?.[this.selectedUserIndex()];
		const userRole = this.userRole();
		const userId = selectedUser?.user.sub as string;
		const groupId = this.group()?.access as string;

		this.#notificationService.openSuccess({ message: 'User role changed successfully' });
		this.#groupsStore.updateUser(groupId, userId, { status: userRole });
	}

	async openAddUser() {
		await this.#modalService.open(
			'user-add',
			{ groupId: this.group()?.access },
			{ width: '480px' },
		);
	}
}
