<form [formGroup]="form" (submit)="onSave($event)">
	<div class="apps-section">
		<div class="apps-section__content">
			@if (form.controls.apps) {
				<csd-app-apps-list />
			}
		</div>

		<footer class="apps-section__actions">
			@if (userStore.deviceToReturn()) {
				<div class="w-full grow">
					<csd-app-button
						[routerLink]="userStore.deviceToReturn()"
						classList="icon-tight"
						icon="chevron_left"
						>Return to Device</csd-app-button
					>
				</div>
			}
			<csd-app-button classList="w-28" routerLink="../../">Cancel</csd-app-button>
			<csd-app-button
				type="tonal"
				classList="w-auto"
				(click)="onSaveAndPush()"
				[disabled]="isLoading()"
			>
				Save and push
			</csd-app-button>
			<csd-app-button
				variation="secondary"
				classList="w-28"
				htmlType="submit"
				[disabled]="isLoading()"
			>
				Save
				@if (isLoading()) {
					<csd-app-loading-icon />
				}
			</csd-app-button>
		</footer>
	</div>
</form>
