import { HttpEvent, HttpRequest } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { ApiService } from '@core/api/api.service';
import { UserStore } from '@feature/user/user.store';
import { environment } from '@root/environments/environment';
import { catchError, finalize, map, Observable, tap } from 'rxjs';

import { DevicesStore } from '../devices/devices.store';
import { AndroidDevice, Note } from './device-details.types';
import { mapDeviceDetails } from './utils/map-device-details';

export interface DeviceAndroidUpdate
	extends Pick<AndroidDevice, 'name' | 'uninstallKey' | 'releaseChannel' | 'workOrder'> {
	group: string;
}

export interface DeviceSettingsUpdate {
	settingId: string;
	setting: {
		androidValue: string;
	};
	value: boolean;
}

export interface InternetUpdateResponse
	extends Pick<
		AndroidDevice,
		| 'active'
		| 'browserProxyConfigURL'
		| 'browserProxyExceptions'
		| 'created'
		| 'id'
		| 'name'
		| 'proxyConfigURL'
		| 'proxyExceptions'
		| 'releaseChannel'
		| 'uninstallKey'
		| 'updated'
		| 'vpnIp'
		| 'vpnOn'
		| 'vpnProxy'
		| 'workOrder'
	> {}

export interface SettingUpdateResponse {
	value: boolean;
	settingId: string;
	setting: null;
	differentThanGroup: boolean;
}

export interface NotesUpdate {
	identifier: string;
	commenter: string;
	comment: string;
}

@Injectable({
	providedIn: 'root',
})
export class DeviceDetailsService {
	readonly #devicesStore = inject(DevicesStore);
	readonly #userStore = inject(UserStore);
	#apiService = inject(ApiService);

	isLoading = signal<boolean>(false);

	deleteDevice(deviceId: string) {
		return this.#apiService.delete(`/android/device/${deviceId}`);
	}

	getDevice(deviceId: string) {
		const params = { params: { access: this.#userStore.selectedGroupId() } };

		this.isLoading.set(true);

		return this.#apiService.get<AndroidDevice>(`/android/device/${deviceId}`, params).pipe(
			map(mapDeviceDetails),
			tap((data) => {
				this.#devicesStore.addDeviceDetail(data);
			}),
			catchError(() => {
				throw new Error('Error getting device details');
			}),
			finalize(() => this.isLoading.set(false)),
		);
	}

	getQrCode(deviceId: string): string {
		return `${environment.baseApi}/android/device/qrcode/${deviceId}`;
	}

	sendCommand(deviceId: string, command: number) {
		this.isLoading.set(true);

		return this.#apiService
			.get(`/android/device/sendcommand/${deviceId}?command=${command}`)
			.pipe(tap(() => this.isLoading.set(false)));
	}

	resetDeviceSetting(deviceId: string, settingId: string) {
		const options = { params: { settingId } };
		const link = `/android/device/settingsvalue/${deviceId}`;

		return this.#apiService.delete<SettingUpdateResponse>(link, options);
	}

	updateDevice(deviceId: string, data: DeviceAndroidUpdate) {
		const params = { params: { access: this.#userStore.selectedGroupId() } };
		const link = `/android/device/${deviceId}`;

		return this.#apiService.put<InternetUpdateResponse>(link, data, params);
	}

	updateDeviceRestriction(deviceId: string, data: DeviceSettingsUpdate) {
		const options = { params: { access: this.#userStore.selectedGroupId() } };
		const updateLink = `/android/device/settingsvalue/${deviceId}`;

		return this.#apiService.put<SettingUpdateResponse>(updateLink, data, options);
	}

	removeTimer(deviceId: string) {
		return this.#apiService.get(`/android/device/removetemporarypolicy/${deviceId}`);
	}

	uploadApk(deviceId: string, file: any): Observable<HttpEvent<any>> {
		const formData = new FormData();
		formData.append('apk', file);
		const req = new HttpRequest(
			'POST',
			`${environment.baseApi}/android/device/installapk/${deviceId}`,
			formData,
			{
				reportProgress: true,
			},
		);

		return this.#apiService.request(req);
	}

	loadNotes(deviceId: string) {
		return this.#apiService.get<Note[]>(`/notes/getbyidentifier/${deviceId}`);
	}

	saveNotes(data: NotesUpdate) {
		return this.#apiService.post<string>('/notes', data);
	}
}
