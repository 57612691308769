import { inject, Injectable, signal } from '@angular/core';
import { ApiService } from '@core/api/api.service';
import { finalize, tap } from 'rxjs';

import { GroupsStore } from '../groups/groups.store';
import { CompanyGroup } from '../groups/groups.types';
import { UserStore } from './user.store';

@Injectable({
	providedIn: 'root',
})
export class UserService {
	readonly #groupsStore = inject(GroupsStore);
	readonly #userStore = inject(UserStore);
	#apiService = inject(ApiService);

	isLoadingGroups = signal<boolean>(false);
	shouldDisplayDashboard = this.#groupsStore.shouldDisplayDashboard;

	getListContent(userId: string) {
		this.isLoadingGroups.set(true);
		const user = this.#userStore.user();

		return this.#apiService
			.get<
				CompanyGroup[]
			>(`/access/listcontent/${userId}`, { params: { name: user.name, username: user.nickname } })
			.pipe(
				tap((data) => {
					const sortedData = data.sort((a, b) =>
						a.id.toString().toLowerCase().localeCompare(b.id.toString().toLowerCase()),
					);

					sortedData.forEach((group) => this.#groupsStore.addGroup(group));
					this.#userStore.setSelectedGroupId(sortedData[0].access);
				}),
				finalize(() => this.isLoadingGroups.set(false)),
			);
	}
}
