<mat-sidenav-container>
	<mat-sidenav
		fixedInViewport
		[mode]="isMobile() ? 'over' : 'side'"
		[opened]="shouldOpenSidenav()"
		(openedChange)="toggleSidenav($event)"
	>
		<csd-app-sidebar [isMobile]="isMobile()"></csd-app-sidebar>
	</mat-sidenav>

	<mat-sidenav
		class="main-layout__drawer"
		mode="over"
		position="end"
		fixedInViewport
		fixedTopGap="64"
		fixedBottomGap="64"
		[opened]="shouldOpenDrawer()"
		(openedChange)="toggleDrawer($event)"
	>
		<div class="flex flex-col h-full px-3">
			<header
				class="sticky top-0 flex justify-between items-center py-3 bg-layout-ghost-white sticky-element sticky--top"
			>
				<button (click)="toggleDrawer(false)" class="leading-none">
					<mat-icon class="hover:animate-bounce-right">chevron_right</mat-icon>
				</button>
				<h2 class="grow text-center text-layout-stone-gray font-bold">{{ drawerData().title }}</h2>
			</header>

			<div class="grow">
				<ng-template [cdkPortalOutlet]="drawerData().component"></ng-template>
			</div>
		</div>
	</mat-sidenav>

	<mat-sidenav-content class="min-h-screen bg-white">
		@if (shouldDisplayDashboard()) {
			<div class="content-mobile">
				<div class="content-mobile__menu">
					<button
						class="rounded-full p-4 flex items-center"
						matRipple
						(click)="toggleSidenav(true)"
					>
						<mat-icon class="align-sub">menu</mat-icon>
					</button>
				</div>
				<div class="content-mobile__logo">
					<img class="h-10" src="../../../../assets/logo-emypeople.png" alt="eMyPeople logo" />
					<p class="tex-sm font-bold leading-none pt-2 pl-2">Dashboard</p>
				</div>
			</div>
			<div class="pl-5 py-2 pr-4 w-full">
				<div class="flex items-center w-full pb-10 min-h-28 flex-col sm:flex-row">
					<div class="w-full pr-5">
						<csd-app-customer-selection />
					</div>
					<div class="flex items-center">
						<csd-app-history />
					</div>
				</div>
				<div class="pb-8">
					<csd-app-breadcrumb />
				</div>
				<csd-app-loader [isLoading]="!!isLoadingRoute()" @fadeInOut>
					<router-outlet></router-outlet>
				</csd-app-loader>
			</div>
		} @else {
			<section class="flex items-center justify-center h-screen">
				<csd-app-loader [isLoading]="isLoadingUserGroups()">
					<div class="max-w-xl px-5 md:px-0 pb-20">
						<csd-app-empty-state icon="report">
							No Dashboard Access
							<ng-content empty-state-message>
								@if (!userHasGroups()) {
									You'll need to connect your account to access and view the dashboard.
								}

								@if (shouldDisplayConnect()) {
									You don't have permission to view the dashboard. Please connect your account to
									gain access.
								}

								@if (userHasGroups() && !shouldDisplayConnect()) {
									You need manager approval to view the dashboard. Please request access from your
									manager.
								}
							</ng-content>
							<ng-content empty-state-actions>
								@if (!userHasGroups() || shouldDisplayConnect()) {
									<a routerLink="/onboarding" class="mt-5">
										<csd-app-button variation="secondary">Connect Your Account</csd-app-button>
									</a>
								}
							</ng-content>
						</csd-app-empty-state>
					</div>
				</csd-app-loader>
			</section>
		}
	</mat-sidenav-content>
</mat-sidenav-container>
