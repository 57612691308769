<div class="policy-restrictions">
	<div class="policy-restrictions__content">
		@for (item of restrictions(); track item.settingId; let i = $index) {
			<csd-app-app-checkbox
				class="w-full"
				[text]="item.setting.name"
				[checked]="item.value"
				(change)="changeSelection($event, $index)"
			></csd-app-app-checkbox>
		}
	</div>

	<div class="policy-restrictions__actions">
		@if (userStore.deviceToReturn()) {
			<div class="w-full grow">
				<csd-app-button
					[routerLink]="userStore.deviceToReturn()"
					classList="icon-tight"
					icon="chevron_left"
					>Return to Device</csd-app-button
				>
			</div>
		}
		<csd-app-button classList="w-28" routerLink="../../">Cancel</csd-app-button>
		<csd-app-button
			type="tonal"
			classList="w-auto"
			(click)="onSaveAndPush()"
			[disabled]="isLoading()"
		>
			Save and push
		</csd-app-button>
		<csd-app-button
			variation="secondary"
			classList="w-28"
			htmlType="submit"
			(click)="onSave()"
			[disabled]="isLoading()"
		>
			Save
			@if (isLoading()) {
				<csd-app-loading-icon />
			}
		</csd-app-button>
	</div>
</div>
