import {
	ChangeDetectionStrategy,
	Component,
	computed,
	inject,
	OnDestroy,
	OnInit,
	signal,
} from '@angular/core';
import { FormControl, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { CustomerChurchGroup } from '@feature/customers/customers.types';
import { PolicyDetailsService } from '@feature/policy-details/policy-details.service';
import { UserStore } from '@feature/user/user.store';
import { triggerOpenClose } from '@root/app/animations';
import { LoadingIconComponent } from '@ui/loading-icon/loading-icon.component';
import { finalize, Subscription } from 'rxjs';

import { CustomersStore } from '../../customers/customers.store';
import { PoliciesService } from '../../policies/policies.service';
import { PoliciesStore } from '../../policies/policies.store';

@Component({
	selector: 'csd-app-general',
	standalone: true,
	imports: [
		FormsModule,
		LoadingIconComponent,
		MatInputModule,
		MatSelectModule,
		ReactiveFormsModule,
	],
	templateUrl: './general.component.html',
	styleUrl: './general.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [triggerOpenClose],
})
export class GeneralComponent implements OnInit, OnDestroy {
	readonly #customersStore = inject(CustomersStore);
	readonly #policiesStore = inject(PoliciesStore);
	readonly #userStore = inject(UserStore);
	#policiesService = inject(PoliciesService);
	#policyDetailsService = inject(PolicyDetailsService);
	#rootFormGroup = inject(FormGroupDirective);

	loadingPolicies = this.#policiesService.isLoading;
	churchGroups = signal<CustomerChurchGroup[]>([]);
	churchGroupLoader = signal<boolean>(false);
	subscriptions$: Subscription[] = [];

	churchControlPlaceholder = computed(() => {
		const description = this.churchGroups().find((group) => !group.id)?.description;

		return description ?? 'No Church Group';
	});

	get form() {
		return this.#rootFormGroup.control;
	}

	get hasNameError() {
		return this.form.dirty && this.form.get('name')?.invalid;
	}

	get nameControl() {
		return this.form.get('name') as FormControl;
	}

	ngOnInit(): void {
		const allGroups = this.#policiesStore.allGroupsNames();

		if (!allGroups.length) {
			this.getAllPolicies();
		}

		this.getChuchGroups();
	}

	ngOnDestroy(): void {
		this.subscriptions$.forEach((sub$) => sub$?.unsubscribe());
	}

	getAllPolicies() {
		const customerId = this.#customersStore.selectedCustomerId();
		let request$ = this.#policiesService.getPublicPolicies();

		if (customerId) {
			const customerCode = this.#customersStore.selectedCustomer()?.code as string;
			request$ = this.#policiesService.getByCustomer(customerCode, customerId);
		}

		request$.subscribe(this.validateName.bind(this));
	}

	getChuchGroups() {
		this.churchGroupLoader.set(true);
		const currentAccess = this.#userStore.selectedGroupId();
		const sub$ = this.#policyDetailsService
			.getChurchGroups(currentAccess)
			.pipe(finalize(() => this.churchGroupLoader.set(false)))
			.subscribe((data) => {
				this.churchGroups.set(data);
			});

		this.subscriptions$.push(sub$);
	}

	validateName() {
		const value = (this.nameControl.value ?? '').trim();
		const allGroups = this.#policiesStore.allGroupsNames();
		const nameExists = allGroups.includes(value);

		if (nameExists) {
			this.nameControl.setErrors({ nameExists: true });
			return;
		}

		this.nameControl.setErrors(null);
	}
}
