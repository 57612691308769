<form class="general-form" [formGroup]="form">
	<div class="general-form__fields">
		<div class="form-element relative">
			<label for="name">Policy Name</label>
			<input
				matInput
				id="name"
				formControlName="name"
				placeholder="Name"
				(focusout)="validateName()"
			/>
			@if (loadingPolicies()) {
				<csd-app-loading-icon classList="absolute top-9 right-2" />
			}
			@if (hasNameError) {
				<div @openClose class="text-error-tertiary px-1">
					@if (nameControl.errors?.['required']) {
						Please enter a policy name.
					}
					@if (nameControl.errors?.['pattern']) {
						Only letters, numbers, space and special characters (- or ') are allowed.
					}
					@if (nameControl.errors?.['nameExists']) {
						A policy with this name already exists.
					}
				</div>
			}
		</div>
		<div class="form-element">
			<label for="group">Church Group</label>
			<mat-select
				class="w-full"
				[class.select-error]="form.controls['churchCode'].errors"
				formControlName="churchCode"
				[placeholder]="churchGroupLoader() ? 'Loading...' : churchControlPlaceholder()"
				hideSingleSelectionIndicator
			>
				<mat-select-trigger>{{ form.controls['churchCode'].value }}</mat-select-trigger>
				@for (church of churchGroups(); track church.id) {
					<mat-option [value]="church.id">
						<span class="pr-2">{{ church.id }}</span>
						<span class="italic text-xs text-layout-shingle-gray">{{ church.description }}</span>
					</mat-option>
				}
			</mat-select>
		</div>
	</div>
</form>
