import { ChangeDetectionStrategy, Component, inject, OnDestroy, signal } from '@angular/core';
import { AndroidSetting } from '@root/app/shared/android-settings.types';
import { AppCheckboxComponent } from '@ui/app-checkbox/app-checkbox.component';
import { LoadingIconComponent } from '@ui/loading-icon/loading-icon.component';
import { NotificationService } from '@ui/notification/notification.service';
import { catchError, finalize, Subscription } from 'rxjs';

import {
	DeviceDetailsService,
	DeviceSettingsUpdate,
	SettingUpdateResponse,
} from '../../device-details/device-details.service';
import { DevicesStore } from '../../devices/devices.store';

@Component({
	selector: 'csd-app-restrictions',
	standalone: true,
	imports: [AppCheckboxComponent, LoadingIconComponent],
	templateUrl: './restrictions.component.html',
	styleUrl: './restrictions.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RestrictionsComponent implements OnDestroy {
	readonly #devicesStore = inject(DevicesStore);
	#deviceDetailsService = inject(DeviceDetailsService);
	#notificationService = inject(NotificationService);

	currentPolicy = signal<AndroidSetting | null>(null);
	currentPolicyIndex = signal<number>(-1);
	device = this.#devicesStore.selectedDevice;
	isItemResetting = signal<string>('');
	subscriptions: Subscription[] = [];

	ngOnDestroy(): void {
		this.subscriptions?.forEach((sub) => sub?.unsubscribe());
	}

	changeSelection(value: boolean, index: number) {
		const policyAndroidSettings = [...this.device().policy.policyAndroidSettings];
		const policyToUpdate = { ...policyAndroidSettings[index] };

		policyToUpdate.value = value;
		this.currentPolicy.set(policyToUpdate);
		this.currentPolicyIndex.set(index);

		this.updateDevice({
			settingId: policyToUpdate.settingId,
			setting: {
				androidValue: policyToUpdate.setting?.androidValue as string,
			},
			value: policyToUpdate.value,
		});
	}

	getCheckedValue(item: AndroidSetting) {
		return this.currentPolicy()?.settingId === item.settingId
			? !!this.currentPolicy()?.value
			: item.value;
	}

	resetSetting(setting: AndroidSetting) {
		const { settingId } = setting;
		this.isItemResetting.set(settingId);
		const reset$ = this.#deviceDetailsService.resetDeviceSetting(this.device().id, settingId);
		const pipe$ = reset$.pipe(
			catchError(() => {
				throw new Error('Error resetting setting');
			}),
			finalize(() => this.isItemResetting.set('')),
		);
		const sub$ = pipe$.subscribe((newSettingValue) => {
			this.#notificationService.openSuccess({ message: 'Setting reset successfully.' });
			this.updateSettingInStore(settingId, {
				value: newSettingValue.value,
				differentThanGroup: newSettingValue.differentThanGroup,
			});
		});

		this.subscriptions.push(sub$);
	}

	updateDevice(data: DeviceSettingsUpdate) {
		const update$ = this.#deviceDetailsService.updateDeviceRestriction(this.device().id, data);
		const pipe$ = update$.pipe(
			catchError(() => {
				this.updateError();
				return [];
			}),
			finalize(() => {
				this.currentPolicy.set(null);
				this.currentPolicyIndex.set(-1);
			}),
		);
		const sub$ = pipe$.subscribe(this.updateSuccess.bind(this));

		this.subscriptions.push(sub$);
	}

	updateError() {
		const settingName = this.currentPolicy()?.setting.name;

		this.#notificationService.openError({
			message: `
        There was an error saving your changes for <strong>${settingName}</strong>.
        Please try updating the restriction again.
      `,
		});
	}

	updateSuccess(setting: SettingUpdateResponse) {
		const settingName = this.currentPolicy()?.setting.name;
		const settingValue = this.currentPolicy()?.value;
		const settingStatus = settingValue ? 'restricted' : 'allowed';

		this.updateSettingInStore(this.currentPolicy()?.settingId as string, {
			value: settingValue,
			differentThanGroup: setting.differentThanGroup,
		});

		this.#notificationService.openSuccess({
			message: `<strong>${settingName}</strong> is now ${settingStatus}.`,
		});
	}

	updateSettingInStore(settingId: string, dataToUpdateLocally: Partial<AndroidSetting>) {
		const newSettingsList = this.device().policy.policyAndroidSettings.map((setting) => {
			if (setting.settingId === settingId) {
				return { ...setting, ...dataToUpdateLocally };
			}

			return setting;
		});

		this.#devicesStore.updateDeviceDetail(this.device().id, {
			policy: { ...this.device().policy, policyAndroidSettings: newSettingsList },
		});
	}
}
