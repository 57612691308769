import { inject, Injectable, signal } from '@angular/core';
import { ApiService } from '@core/api/api.service';
import { CustomerChurchGroup } from '@feature/customers/customers.types';
import { AndroidAppServerResponse } from '@root/app/utils/map-policy-apps';
import { catchError, finalize, map, tap } from 'rxjs';

import { PoliciesStore } from '../policies/policies.store';
import { Group } from '../policies/policies.types';
import { Policy, PolicyOwner } from './policy-details.types';
import { mapGroupResponse } from './utils/map-group-response';

export interface GroupServerResponse extends Omit<Group, 'policy'> {
	policy: Omit<Policy, 'policyAppJoins'> & {
		policyAppJoins: AndroidAppServerResponse[];
	};
}

export interface GroupGeneralUpdate extends Pick<Group, 'name' | 'churchCode' | 'policyId'> {
	customUser?: string;
}

export interface GroupAppsUpdate {
	appId: string;
	packageName: string;
	status: number;
	internetConfig: number;
}

export interface GroupRestrictionsUpdate {
	settingId: string;
	setting: {
		androidValue: string;
	};
	value: boolean;
}

@Injectable({
	providedIn: 'root',
})
export class PolicyDetailsService {
	readonly #policiesStore = inject(PoliciesStore);
	#apiService = inject(ApiService);

	isLoadingDetails = signal<boolean>(false);

	getChurchGroups(accessId: string) {
		return this.#apiService.get<CustomerChurchGroup[]>(`/churchgroup/getbyaccess/${accessId}`);
	}

	getGroup(groupId: string) {
		this.isLoadingDetails.set(true);

		return this.#apiService.get<GroupServerResponse>(`/android/groups/${groupId}`).pipe(
			map(mapGroupResponse),
			tap((data) => {
				this.#policiesStore.addGroupDetails(data);
				this.#policiesStore.updateSelectedGroupId(data.id);
			}),
			catchError((err) => {
				throw new Error(err.error?.title || err.message);
			}),
			finalize(() => this.isLoadingDetails.set(false)),
		);
	}

	getOwnerOptions(groupId: string) {
		return this.#apiService.get<PolicyOwner[]>(`/android/groups/getowneroptions/${groupId}`).pipe(
			tap((data) => {
				if (Array.isArray(data)) {
					this.#policiesStore.addOwners(groupId, data);
				}
			}),
		);
	}

	simpleUpdateGroup(groupId: string, data: GroupGeneralUpdate) {
		return this.#apiService.put<void>(`/android/groups/${groupId}`, data);
	}

	updateApps(groupId: string, data: GroupAppsUpdate[], pushChanges: boolean = false) {
		return this.#apiService.put<void>(`/android/groups/appstatus/${groupId}`, data, {
			params: { pushChanges },
		});
	}

	updateRestrictions(
		groupId: string,
		data: GroupRestrictionsUpdate[],
		pushChanges: boolean = false,
	) {
		return this.#apiService.put<void>(`/android/groups/settingvalue/${groupId}`, data, {
			params: { pushChanges },
		});
	}
}
