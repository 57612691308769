import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandlerFn,
	HttpInterceptorFn,
	HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';

import { AuthService } from '../auth/auth.service';

export const retryInterceptor: HttpInterceptorFn = (request, next) => {
	const authService = inject(AuthService);
	const tokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
	let isRefreshingToken = false;

	const addTokenHeader = (request: HttpRequest<any>, token: string): HttpRequest<any> => {
		return request.clone({
			setHeaders: {
				Authorization: `Bearer ${token}`,
			},
		});
	};

	const handle401Error = (
		request: HttpRequest<any>,
		next: HttpHandlerFn,
	): Observable<HttpEvent<any>> => {
		if (!isRefreshingToken) {
			isRefreshingToken = true;
			tokenSubject.next(null);

			return authService.refreshToken().pipe(
				switchMap((token) => {
					isRefreshingToken = false;
					tokenSubject.next(token.accessToken);
					return next(addTokenHeader(request, token.accessToken));
				}),
				catchError((err) => {
					isRefreshingToken = false;
					tokenSubject.next(null);
					authService.logout();
					throw new Error(err);
				}),
				finalize(() => {
					isRefreshingToken = false;
				}),
			);
		} else {
			return tokenSubject.pipe(
				filter((token) => token !== null),
				take(1),
				switchMap((token) => {
					return next(addTokenHeader(request, token!));
				}),
			);
		}
	};

	return next(request).pipe(
		catchError((error: HttpErrorResponse) => {
			if (error.status === 401) {
				return handle401Error(request, next);
			}

			return throwError(() => error);
		}),
	);
};
