import { inject, Injectable } from '@angular/core';
import { ApiService } from '@root/app/core/api/api.service';
import { tap } from 'rxjs';

import { GroupsStore } from '../groups/groups.store';
import { UserGroup, UserGroupBase } from '../groups/groups.types';

export type CompanyGroupUpdate = {
	name: string;
	isGroup: true;
};

export type CompanyUserUpdate = {
	status: number;
	dashboard: true;
	userSub: string;
};

@Injectable({
	providedIn: 'root',
})
export class GroupDetailService {
	readonly #groupsStore = inject(GroupsStore);
	#apiService = inject(ApiService);

	addUserToGroup(groupId: string, user: CompanyUserUpdate) {
		return this.#apiService.post<void>(`/access/giveaccesstogroup/${groupId}`, user);
	}

	getGroupUsers(access: string) {
		return this.#apiService
			.get<UserGroup[]>(`/access/getusers/${access}`)
			.pipe(tap((data) => this.#groupsStore.addGroupUsers(access, data)));
	}

	searchUsers(value: string) {
		return this.#apiService.get<UserGroupBase[]>(`/access/searchforusers/`, {
			params: { searchTerm: value },
		});
	}

	updateGroupData(groupId: string, data: CompanyGroupUpdate) {
		return this.#apiService.put<void>(`/access/editgroup/${groupId}`, data);
	}

	updateUserData(groupId: string, data: CompanyUserUpdate) {
		return this.#apiService.put<void>(`/access/giveaccesstogroup/${groupId}`, data);
	}

	removeUser(groupId: string, userId: string) {
		return this.#apiService.delete<void>(`/access/removeaccess/${groupId}`, {
			params: { sub: userId },
		});
	}
}
