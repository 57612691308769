import { ComponentPortal, ComponentType, Portal } from '@angular/cdk/portal';
import { computed, Injectable, signal } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class MainLayoutService {
	#isSidenavOpen = signal(false);
	#isDrawerOpen = signal(false);

	drawerTitle = signal('');
	drawerComponent = signal<Portal<any> | null>(null);

	isDrawerOpen = computed(() => this.#isDrawerOpen());
	isSidenavOpen = computed(() => this.#isSidenavOpen());

	closeDrawer() {
		this.#isDrawerOpen.set(false);
		this.drawerComponent.set(null);
		this.drawerTitle.set('');
	}

	openDrawer<T>(title: string, component: ComponentType<T>) {
		if (this.drawerComponent() || this.drawerTitle()) {
			this.closeDrawer();
		}

		const portalInstance = new ComponentPortal(component);

		this.drawerComponent.set(portalInstance);
		this.drawerTitle.set(title);
		this.#isDrawerOpen.set(true);
	}

	toggleSidenav(status: boolean) {
		this.#isSidenavOpen.set(status);
	}
}
