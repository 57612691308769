<div class="overview-comp">
	<div class="overview-comp__content grow" [formGroup]="form">
		@if (!customer()) {
			<csd-app-empty-state>
				<span>
					We couldn't find a customer with code
					<strong>{{ customer()?.code }}</strong>
				</span>
				<ng-content empty-state-message>
					<span
						>Search by name instead or go to the <a routerLink="/">customers list</a> page.</span
					>
				</ng-content>
			</csd-app-empty-state>
		} @else {
			<csd-app-header-divider text="My Information" classList="pb-4" />
			<p class="text-2xl text-layout-stone-gray font-bold flex items-center pb-4">
				{{ customer()?.name }}
				<span class="bg-layout-sky p-2 ml-5 font-normal text-base rounded">{{
					customer()?.code
				}}</span>
			</p>
			<div class="additional-information space-y-2.5">
				<p>
					<mat-icon inline class="w-6 align-top material-symbols-filled">location_on</mat-icon>
					{{ customer()?.streetAddress }}
				</p>
				<p>
					<mat-icon inline class="w-6 align-top material-symbols-filled">mail</mat-icon>
					{{ customer()?.email }}
				</p>
				<p>
					<mat-icon inline class="w-6 align-top material-symbols-filled">phone_enabled</mat-icon>
					{{ customer()?.officePhone }}
				</p>
				@if (!customer()?.canEditChurchGroup) {
					<p>
						<mat-icon inline class="w-6 align-top material-symbols-filled">groups</mat-icon>
						{{ churchGroupLabel }}
					</p>
				}
			</div>
			@if (customer()?.canEditChurchGroup) {
				<div class="pt-5">
					<mat-select
						class="max-w-96"
						[class.select-error]="form.controls.churchGroupType.errors"
						formControlName="churchGroupType"
						placeholder="No Church Group"
						(selectionChange)="updateChanges()"
						hideSingleSelectionIndicator
					>
						<mat-select-trigger>
							Church Group
							{{
								form.getRawValue().churchGroupType || customer()?.churchGroupType
							}}</mat-select-trigger
						>
						@for (church of churchGroups(); track church.id) {
							<mat-option [value]="church.id">
								<span class="pr-2">{{ church.id }}</span>
								<span class="italic text-xs text-layout-shingle-gray">{{
									church.description
								}}</span>
							</mat-option>
						}
					</mat-select>
				</div>
			}
			<csd-app-header-divider text="Notification Preferences" classList="pt-16 pb-4" />
			<div class="space-y-4">
				<p>
					<mat-slide-toggle
						formControlName="dataUsageNotices"
						(change)="dataUsageChange($event)"
						class="mat-slide-toggle-small"
						hideIcon
					>
						<span class="pl-5 text-base">Data Usage Notices</span>
					</mat-slide-toggle>
				</p>
				<p class="time-selector pt-5" [class.opacity-50]="!form.value.dataUsageNotices">
					<mat-select
						class="text-center"
						[class.select-error]="form.controls.frequencyInDays.errors?.['min']"
						formControlName="frequencyInDays"
						(selectionChange)="updateChanges()"
						hideSingleSelectionIndicator
					>
						<mat-select-trigger>
							{{ form.value.frequencyInDays }}
						</mat-select-trigger>
						@for (days of recurringDays; track days.label) {
							<mat-option class="option-small" [value]="days.value">{{ days.label }}</mat-option>
						}
					</mat-select>
					<span class="grow pl-4">Days between recurring reminders</span>
				</p>
				<p class="time-selector" [class.opacity-50]="!form.value.dataUsageNotices">
					<mat-select
						class="text-center"
						[class.select-error]="form.controls.spanBeforeAlertInDays.errors?.['min']"
						formControlName="spanBeforeAlertInDays"
						(selectionChange)="updateChanges()"
						hideSingleSelectionIndicator
					>
						<mat-select-trigger>
							{{ form.value.spanBeforeAlertInDays }}
						</mat-select-trigger>
						@for (days of daysAlerted; track days.label) {
							<mat-option class="option-small" [value]="days.value">{{ days.label }}</mat-option>
						}
					</mat-select>
					<span class="grow pl-4">
						Days before alerts start
						<mat-icon
							inline
							#tooltip="matTooltip"
							matTooltip="Alerts can be muted after the plan starts"
							class="cursor-pointer align-bottom"
						>
							info
						</mat-icon>
					</span>
				</p>
			</div>
		}
	</div>
	<div class="overview-comp__notes max-w-lg"></div>
</div>
