import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';

import { UserStore } from '../user/user.store';

export const policieDetailsGuard: CanDeactivateFn<unknown> = () => {
	const userStore = inject(UserStore);

	userStore.setDeviceToReturn('');

	return true;
};
