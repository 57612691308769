<div class="button-group hover:opacity-80 text-layout-stone-gray" [ngClass]="classList()">
	<button class="grow text-left py-2 px-4 truncate" matRipple (click)="clicked.emit()">
		<ng-content></ng-content>
	</button>
	<button
		class="p-2"
		[class.hidden]="!hasProjectedContent()"
		#buttonGroupAfter
		matRipple
		(click)="clickedAfter.emit()"
	>
		<ng-content select="[button-group-after]"></ng-content>
	</button>
</div>
