import { Component, inject, OnDestroy, OnInit, signal } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { Event, ResolveEnd, Router, RouterOutlet } from '@angular/router';
import { Subscription, tap } from 'rxjs';

import { AppService } from './app.service';
import { ActivityService } from './core/activity/activity.service';
import { ConfigService } from './feature/config/config.service';
import { LoaderFullScreenComponent } from './ui/loader-full-screen/loader-full-screen.component';

@Component({
	selector: 'csd-app-root',
	standalone: true,
	imports: [LoaderFullScreenComponent, RouterOutlet],
	providers: [AppService],
	templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
	#activityService = inject(ActivityService);
	#appService = inject(AppService);
	#configService = inject(ConfigService);
	#matIconReg = inject(MatIconRegistry);
	#router = inject(Router);

	loadingText = signal<string>('Loading interface');
	title = 'Control Stack Dashboard';
	subscriptions: Subscription[] = [];
	isLoading = signal<boolean>(false);

	ngOnInit(): void {
		this.isLoading.set(true);

		const routerSub$ = this.#router.events.subscribe((event: Event) => {
			if (event instanceof ResolveEnd) {
				this.isLoading.set(false);
			}

			this.#appService.updateSelectedCustomer(event);
		});

		const authSub$ = this.#appService
			.loadRequiredData()
			.pipe(tap(() => this.loadingText.set('Loading user data')))
			.subscribe(this.loadConfig.bind(this));

		this.#matIconReg.setDefaultFontSetClass('material-symbols-outlined');
		this.#activityService.addListeners();
		this.subscriptions.push(authSub$, routerSub$);
	}

	ngOnDestroy(): void {
		this.#activityService.removeListeners();
		this.subscriptions?.forEach((sub) => sub?.unsubscribe());
	}

	loadConfig() {
		const options$ = this.#configService.getAppOptions().subscribe();
		this.subscriptions.push(options$);
	}
}
